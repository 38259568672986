import { createModel } from '@rematch/core'
import { api } from '@/api'
import { ENContactApiUpdateENContactRequest, ENContactDto } from '@/api/generated'
import type { RootModel } from '.'

type ContactState = {
  contacts?: ENContactDto
}

export const contact = createModel<RootModel>()({
  state: {} as ContactState,
  reducers: {
    setContacts(state, payload: ENContactDto) {
      state.contacts = payload
    }
  },
  effects: dispatch => {
    const { contact } = dispatch

    return {
      async getContact(): Promise<void> {
        const { data } = await api.contact.getENContact()

        contact.setContacts(data)
      },

      async updateContact({ eNContactDto }: ENContactApiUpdateENContactRequest): Promise<void> {
        await api.contact.updateENContact({ eNContactDto })
      }
    }
  }
})
