import { Models } from '@rematch/core'
import { auditlog } from './auditlog.model'
import { auth } from './auth.model'
import { calendar } from './calendar.model'
import { contact } from './contact.model'
import { contract } from './contract.model'
import { customers } from './customers.model'
import { device } from './device.model'
import { deviceType } from './device-type.model'
import { energyCost } from './energy-cost.model'
import { errors } from './errors.model'
import { invoice } from './invoice.model'
import { prediction } from './prediction.model'
import { profile } from './profile.model'
import { settings } from './settings.model'
import { statement } from './statement.model'
import { users } from './users.model'

export interface RootModel extends Models<RootModel> {
  auditlog: typeof auditlog
  auth: typeof auth
  contact: typeof contact
  contract: typeof contract
  customers: typeof customers
  device: typeof device
  deviceType: typeof deviceType
  errors: typeof errors
  prediction: typeof prediction
  profile: typeof profile
  settings: typeof settings
  users: typeof users
  calendar: typeof calendar
  energyCost: typeof energyCost
  statement: typeof statement
  invoice: typeof invoice
}

export const models: RootModel = {
  auditlog,
  auth,
  customers,
  contact,
  contract,
  device,
  deviceType,
  errors,
  prediction,
  profile,
  settings,
  users,
  calendar,
  energyCost,
  statement,
  invoice
}
