import userService from '@/services/user'
import axios from './axios'
import { Configuration } from './generated'
import {
  AuditLogApi,
  AuthApi,
  CalendarApi,
  ContractApi,
  ControlPanelApi,
  CustomerApi,
  DeviceApi,
  DeviceTypeApi,
  ENContactApi,
  EnergyCostApi,
  InvoiceApi,
  MonthlyMaintenanceCostApi,
  PaymentApi,
  PredictionApi,
  StatementApi,
  UserApi
} from './generated/api'

const basePath = (import.meta.env.VITE_API_URL as string) ?? import.meta.env.BASE_URL
const config: Configuration = new Configuration({
  apiKey: () => `Bearer ${userService.getToken().accessToken}`,
  basePath
})

export const api = {
  auditlog: new AuditLogApi(config, basePath, axios),
  auth: new AuthApi(config, basePath, axios),
  calendar: new CalendarApi(config, basePath, axios),
  contact: new ENContactApi(config, basePath, axios),
  contract: new ContractApi(config, basePath, axios),
  controlPanel: new ControlPanelApi(config, basePath, axios),
  customer: new CustomerApi(config, basePath, axios),
  device: new DeviceApi(config, basePath, axios),
  deviceType: new DeviceTypeApi(config, basePath, axios),
  energyCost: new EnergyCostApi(config, basePath, axios),
  invoice: new InvoiceApi(config, basePath, axios),
  monthlyMaintenanceCost: new MonthlyMaintenanceCostApi(config, basePath, axios),
  payment: new PaymentApi(config, basePath, axios),
  prediction: new PredictionApi(config, basePath, axios),
  statement: new StatementApi(config, basePath, axios),
  user: new UserApi(config, basePath, axios)
}
